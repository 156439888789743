import styles from '../styles/Landing.module.css';
import Link from 'next/link';

export default function Footer() {
  return (
    <div className={`${styles['section-footer']} red`}>
      <div className={`${styles['section-footer-logo']} red`}>
        <Link href="/">
          <img src="/landing/footer-logo.svg" />
        </Link>
      </div>
      <div className={`${styles['section-footer-contact']} red`}>
        hello@fauna.com
      </div>
      <div className={`${styles['section-footer-social']} red`}>
        <Link href="/">
          <div className={`${styles['section-footer-social-btn']} red`}>
            <img src="/landing/twitter.svg" />
          </div>
        </Link>
        <Link href="/">
          <div className={`${styles['section-footer-social-btn']} red`}>
            <img src="/landing/facebook.svg" />
          </div>
        </Link>
        <Link href="/">
          <div className={`${styles['section-footer-social-btn']} red`}>
            <img src="/landing/insta.svg" />
          </div>
        </Link>
      </div>
      <div className={`${styles['section-footer-terms']} red`}>
        <span>
          Fauna © 2021.{' '}
          <Link href="/">
            <span className={`${styles['section-footer-link']} red`}>
              Terms
            </span>
          </Link>{' '}
          and{' '}
          <Link href="/">
            <span className={`${styles['section-footer-link']} red`}>
              Privacy
            </span>
          </Link>
          .
        </span>
      </div>
    </div>
  );
}
