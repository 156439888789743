import styles from '../styles/Landing.module.css';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { auth } from '../lib/firebase';
import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../lib/context';
import toast from 'react-hot-toast';

export default function Navbar() {
  const [ham, setHam] = useState(false);
  const { user } = useContext(UserContext);
  const router = useRouter();

  // useEffect(() => {
  //   console.log(ham);
  // }, [ham]);

  // useEffect(() => {
  //   console.log(user);
  // }, [user]);

  const logout = () => {
    auth.signOut();
    router.push('https://gemmahealth.ca');
    toast.success('Successfully logged out.');
  };

  const logoutMobile = () => {
    auth.signOut();
    setHam(!ham);
    router.push('https://gemmahealth.ca');
    toast.success('Successfully logged out.');
  };

  return (
    <>
      <nav className={`${styles['navbar-container']} red`}>
        {/* <div className={`${styles['link-left']} red`}>
          <Link href="/about">About Us</Link>
        </div>
        <div className={`${styles['link-left']} red`}>
          <Link href="/faq">FAQ</Link>
        </div> */}
        <div className={`${styles['fauna-logo']} red`}>
          <Link href="https://gemmahealth.ca">
            <Image src="/landing/gemma-red.png" width={186} height={43} />
          </Link>
        </div>
        {user ? (
          <div className={`${styles['link-right']} red`} onClick={logout}>
            Logout
          </div>
        ) : (
          <div className={`${styles['link-right']} red`}>
            <Link href="/login">Login</Link>
          </div>
        )}

        <div className={`${styles['']} red`}>
          <Link href="/birth-control">
            <button className={`${styles['navbar-cta-btn']} red`}>
              Get Started
            </button>
          </Link>
        </div>

        <div className={`${styles['push-left']} red`}>
          <button
            onClick={() => setHam(!ham)}
            className={`${styles['navbar-hamburger']} red`}
          >
            <Image src="/landing/menu-hamburger.svg" width={40} height={40} />
          </button>
        </div>
      </nav>
      <div
        className={`${styles['mobile-menu']} red`}
        style={{ display: ham ? 'block' : 'none' }}
      >
        <nav className={`${styles['navbar-container-mobile']} red`}>
          <div className={`${styles['fauna-logo']} red`}>
            <Link href="https://gemmahealth.ca">
              <Image src="/landing/gemma.png" width={120} height={26} />
            </Link>
          </div>
          <div className={`${styles['push-left']} red`}>
            <button
              onClick={() => setHam(!ham)}
              className={`${styles['navbar-hamburger2']} red`}
            >
              <Image src="/landing/menu-hamburger.svg" width={40} height={40} />
            </button>
          </div>
        </nav>
        {/* <div
          className={`${styles['mobile-link']} red`}
          onClick={() => setHam(!ham)}
        >
          <Link href="/about">About Us</Link>
        </div>
        <div
          className={`${styles['mobile-link']} red`}
          onClick={() => setHam(!ham)}
        >
          <Link href="/faq">FAQ</Link>
        </div> */}

        {user ? (
          <div
            className={`${styles['mobile-link']} red`}
            onClick={logoutMobile}
          >
            Logout
          </div>
        ) : (
          <div
            className={`${styles['mobile-link']} red`}
            onClick={() => setHam(!ham)}
          >
            <Link href="/login">Login</Link>
          </div>
        )}

        <Link href="/birth-control">
          <button className={`${styles['navbar-cta-btn2']} red`}>
            Get Started
          </button>
        </Link>
      </div>
    </>
  );
}
