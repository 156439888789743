import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import styles from '../styles/Landing.module.css';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';

export default function Landing() {
  const router = useRouter();

  if (typeof window !== 'undefined') {
    if (window.location.href !== 'http://localhost:3000/') {
      // console.log(window.location.href);
      router.push('https://gemmahealth.ca');
    }
  }

  // useEffect(() => {
  //   console.log(router);
  //   // router.push('https://gemmahealth.ca');
  // }, []);

  return (
    <div className={`red`}>
      <Head>
        <title>Gemma Health</title>
        <meta name="Gemma Health" content="" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Navbar />
      <Banner />
      <Hero />
      <Logos />
      <How />

      <Medical />
      <Pricing />
      <Brands />
      <Saying />
      <Quotes />
      <Why />
      <Started />
      <Privacy />
      <Footer />
    </div>
  );
}

function Fade({ children }) {
  const cardVariants = {
    offscreen: {
      y: 25,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        bounce: 0.1,
        duration: 2,
      },
    },
  };
  return (
    <motion.div
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 1 }}
      variants={cardVariants}
    >
      {children}
    </motion.div>
  );
}

function Banner() {
  return (
    <div className={`${styles['section-banner']} red`}>
      <div className={`${styles['banner-text']} red`}>
        <span>$0 w/ insurance. As low as $6.99 without.</span>
        <span className={`${styles['banner-started']} red`}>
          <Link href="/birth-control">Get Started</Link>
        </span>
      </div>
    </div>
  );
}

function Hero() {
  return (
    <div className={`${styles['section-hero']} red`}>
      <div className={`${styles['section-hero-container']} red`}>
        <div className={`${styles['section-hero-left']} red`}>
          <div className={`${styles['section-hero-tagline']} red`}>
            The fastest way to get your birth control
          </div>
          <div className={`${styles['section-hero-bullet']} red`}>
            <div className={`${styles['section-hero-bullet-icon']} red`}>
              <Image src="/landing/hero-check.svg" width={24} height={24} />
            </div>
            <div className={`${styles['section-hero-bullet-text']} red`}>
              Get or Renew Your Prescription Online.
            </div>
          </div>
          <div className={`${styles['section-hero-bullet']} red`}>
            <div className={`${styles['section-hero-bullet-icon']} red`}>
              <Image src="/landing/hero-check.svg" width={24} height={24} />
            </div>
            <div className={`${styles['section-hero-bullet-text']} red`}>
              Free Delivery.
            </div>
          </div>
          <div className={`${styles['section-hero-bullet']} red`}>
            <div className={`${styles['section-hero-bullet-icon']} red`}>
              <Image src="/landing/hero-check.svg" width={24} height={24} />
            </div>
            <div className={`${styles['section-hero-bullet-text']} red`}>
              $0 With Insurance. Best Price Without.
            </div>
          </div>
          <Link href="/birth-control">
            <button className={`${styles['section-hero-btn']} red`}>
              Get Started
            </button>
          </Link>
        </div>
        <div className={`${styles['section-hero-right']} red`}>
          <div className={`${styles['section-hero-img1']} red`}>
            <img src="/landing/hero-full.svg" />
          </div>
          <div className={`${styles['section-hero-img2']} red`}>
            <img src="/landing/hero-line.svg" />
          </div>
          <div className={`${styles['section-hero-img3']} red`}>
            <img src="/landing/hero-hand.svg" />
          </div>
        </div>
      </div>
    </div>
  );
}

function Logos() {
  return (
    <div className={`${styles['section-logos']} red`}>
      <div className={`${styles['section-logos-item']} red`}>
        <div className={`${styles['section-logos-icon']} red`}>
          <Image src="/landing/overview1.svg" width={64} height={64} />
        </div>
        <div className={`${styles['section-logos-text']} red`}>
          Licensed Pharmacy
        </div>
      </div>

      <div className={`${styles['section-logos-item']} red`}>
        <div className={`${styles['section-logos-icon']} red`}>
          <Image src="/landing/overview2.svg" width={64} height={64} />
        </div>
        <div className={`${styles['section-logos-text']} red`}>
          Canadian Licensed Doctors
        </div>
      </div>
      <div className={`${styles['section-logos-item']} red`}>
        <div className={`${styles['section-logos-icon']} red`}>
          <Image src="/landing/overview3.svg" width={64} height={64} />
        </div>
        <div className={`${styles['section-logos-text']} red`}>
          Approved Medication
        </div>
      </div>
    </div>
  );
}

function How() {
  return (
    <div className={`${styles['section-how']} red`}>
      <div className={`${styles['section-how-title']} red`}>
        <Fade>How it works</Fade>
      </div>
      <div className={`${styles['section-how-contain']} red`}>
        <div className={`${styles['section-how-container']} red`}>
          <Fade>
            <div className={`${styles['section-how-item']} red`}>
              <div className={`${styles['section-how-icon']} red`}>
                <Image src="/landing/hiw1.svg" width={80} height={105} />
              </div>
              <div className={`${styles['section-how-text']} red`}>
                Take a few minutes to fill out your online health profile.
              </div>
            </div>
          </Fade>
          <Fade>
            <div className={`${styles['section-how-item']} red`}>
              <div className={`${styles['section-how-icon']} red`}>
                <Image src="/landing/hiw2.svg" width={80} height={105} />
              </div>
              <div className={`${styles['section-how-text']} red`}>
                Our medical team reviews, works with you to select the best
                option and prescribes.
              </div>
            </div>
          </Fade>
          <Fade>
            <div className={`${styles['section-how-item']} red`}>
              <div className={`${styles['section-how-icon']} red`}>
                <Image src="/landing/hiw3.svg" width={80} height={105} />
              </div>
              <div className={`${styles['section-how-text']} red`}>
                Delivered to your door on time, every time. Never worry about
                running out.
              </div>
            </div>
          </Fade>
          <Fade>
            <div className={`${styles['section-how-item']} red`}>
              <div className={`${styles['section-how-icon']} red`}>
                <Image src="/landing/hiw4.svg" width={80} height={105} />
              </div>
              <div className={`${styles['section-how-text']} red`}>
                Receive personalized care. Text us anytime for ongoing care. No
                appointment needed.
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}

function Medical() {
  return (
    <div className={`${styles['section-medical']} red`}>
      <div className={`${styles['section-medical-container']} red`}>
        <div className={`${styles['section-medical-left']} red`}>
          <div className={`${styles['section-medical-img1']} red`}>
            <img src="/landing/medical1.svg" />
          </div>
          <div className={`${styles['section-medical-img2']} red`}>
            <img src="/landing/medical2.svg" />
          </div>
          <div className={`${styles['section-medical-img3']} red`}>
            <Fade>
              <img src="/landing/medical3.png" />
            </Fade>
          </div>
        </div>
        <div className={`${styles['section-medical-right']} red`}>
          <div className={`${styles['section-medical-tagline']} red`}>
            <Fade>Our medical team puts your reproductive health first</Fade>
          </div>
          <div className={`${styles['section-medical-sub']} red`}>
            <Fade>
              With decades of experience in reproductive health, our team of
              Canadian licensed medical professionals are here to help. Chat
              with us about anything: contraceptive options, medication side
              effects, changing prescriptions, or anything else!
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}

function Pricing() {
  return (
    <div className={`${styles['section-pricing']} red`}>
      <div className={`${styles['section-pricing-title']} red`}>
        <Fade>Insurance or Out-of-Pocket?</Fade>
      </div>
      <div className={`${styles['section-pricing-container']} red`}>
        <div className={`${styles['section-pricing-item']} red`}>
          <div className={`${styles['section-pricing-item-title']} red`}>
            Insurance
          </div>
          <div className={`${styles['section-pricing-item-price']} red`}>
            $0
          </div>
          <div className={`${styles['section-pricing-item-price3']} red`}></div>
          <div
            className={`${styles['section-pricing-item-bullet-container']} red`}
          >
            <div className={`${styles['section-pricing-item-bullet']} red`}>
              <div className={`${styles['section-pricing-item-icon']} red`}>
                <Image
                  src="/landing/pricing-check.svg"
                  width={24}
                  height={24}
                />
              </div>
              <div className={`${styles['section-pricing-item-text']} red`}>
                $0 for online consult & prescription. Personalized ongoing care
                included.
              </div>
            </div>
            <div className={`${styles['section-pricing-item-bullet']} red`}>
              <div className={`${styles['section-pricing-item-icon']} red`}>
                <Image
                  src="/landing/pricing-check.svg"
                  width={24}
                  height={24}
                />
              </div>
              <div className={`${styles['section-pricing-item-text']} red`}>
                Free, discreet delivery, and auto-refills.
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles['section-pricing-item']} red`}>
          <div className={`${styles['section-pricing-item-title']} red`}>
            Out-of-pocket
          </div>
          <div className={`${styles['section-pricing-item-price']} red`}>
            $6.99
          </div>
          <div className={`${styles['section-pricing-item-price2']} red`}>
            As low as, per month
          </div>
          <div
            className={`${styles['section-pricing-item-bullet-container']} red`}
          >
            <div className={`${styles['section-pricing-item-bullet']} red`}>
              <div className={`${styles['section-pricing-item-icon']} red`}>
                <Image
                  src="/landing/pricing-check.svg"
                  width={24}
                  height={24}
                />
              </div>
              <div className={`${styles['section-pricing-item-text']} red`}>
                $15/year for online consult and prescription. Personalized
                ongoing care included.
              </div>
            </div>
            <div className={`${styles['section-pricing-item-bullet']} red`}>
              <div className={`${styles['section-pricing-item-icon']} red`}>
                <Image
                  src="/landing/pricing-check.svg"
                  width={24}
                  height={24}
                />
              </div>
              <div className={`${styles['section-pricing-item-text']} red`}>
                Free, discreet delivery, and auto-refills.
              </div>
            </div>
            <div className={`${styles['section-pricing-item-bullet']} red`}>
              <div className={`${styles['section-pricing-item-icon']} red`}>
                <Image
                  src="/landing/pricing-check.svg"
                  width={24}
                  height={24}
                />
              </div>
              <div className={`${styles['section-pricing-item-text']} red`}>
                No commitment. Cancel anytime.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Brands() {
  return (
    <div className={`${styles['section-brands']} red`}>
      <div className={`${styles['section-brands-title']} red`}>
        <Fade>
          <div>We carry 100+ brands approved by Health Canada</div>
        </Fade>
      </div>
      <div className={`${styles['section-brands-container']} red`}>
        <Fade>
          <div className={`${styles['section-brands-image']} red`}>
            <img src="/landing/image8.png" />
          </div>
        </Fade>
        <Fade>
          <div className={`${styles['section-brands-image']} red`}>
            <img src="/landing/image9.png" />
          </div>
        </Fade>
        <Fade>
          <div className={`${styles['section-brands-image']} red`}>
            <img src="/landing/image10.png" />
          </div>
        </Fade>
        <Fade>
          <div className={`${styles['section-brands-image']} red`}>
            <img src="/landing/image11.png" />
          </div>
        </Fade>
        <Fade>
          <div className={`${styles['section-brands-image']} red`}>
            <img src="/landing/image12.png" />
          </div>
        </Fade>
        <Fade>
          <div className={`${styles['section-brands-image']} red`}>
            <img src="/landing/image13.png" />
          </div>
        </Fade>
      </div>
    </div>
  );
}

function Saying() {
  return (
    <div className={`${styles['section-saying']} red`}>
      <div className={`${styles['section-saying-title']} red`}>
        <Fade>What people are saying</Fade>
      </div>
      <div className={`${styles['section-saying-container']} red`}>
        <Fade>
          <div className={`${styles['section-saying-item']} red`}>
            <div className={`${styles['section-saying-item-top']} red`}>
              <div className={`${styles['section-saying-item-img']} red`}>
                <img src="/landing/saying-top.png" />
              </div>
              <div className={`${styles['section-saying-item-name']} red`}>
                michelle.obama
              </div>
            </div>
            <div className={`${styles['section-saying-item-mid']} red`}>
              {/* <img src="/landing/saying-mid.png" /> */}
            </div>
            <div className={`${styles['section-saying-item-bot']} red`}>
              <div className={`${styles['section-saying-item-title']} red`}>
                michelle.obama
              </div>
              <div className={`${styles['section-saying-item-sub']} red`}>
                Just got my @fauna package!! I am so happy with my package!!
              </div>
            </div>
          </div>
        </Fade>
        <Fade>
          <div className={`${styles['section-saying-item']} red`}>
            <div className={`${styles['section-saying-item-top']} red`}>
              <div className={`${styles['section-saying-item-img']} red`}>
                <img src="/landing/saying-top.png" />
              </div>
              <div className={`${styles['section-saying-item-name']} red`}>
                michelle.obama
              </div>
            </div>
            <div className={`${styles['section-saying-item-mid']} red`}>
              {/* <img src="/landing/saying-mid.png" /> */}
            </div>
            <div className={`${styles['section-saying-item-bot']} red`}>
              <div className={`${styles['section-saying-item-title']} red`}>
                michelle.obama
              </div>
              <div className={`${styles['section-saying-item-sub']} red`}>
                Just got my @fauna package!! I am so happy with my package!!
              </div>
            </div>
          </div>
        </Fade>
        <Fade>
          <div className={`${styles['section-saying-item']} red`}>
            <div className={`${styles['section-saying-item-top']} red`}>
              <div className={`${styles['section-saying-item-img']} red`}>
                <img src="/landing/saying-top.png" />
              </div>
              <div className={`${styles['section-saying-item-name']} red`}>
                michelle.obama
              </div>
            </div>
            <div className={`${styles['section-saying-item-mid']} red`}>
              {/* <img src="/landing/saying-mid.png" /> */}
            </div>
            <div className={`${styles['section-saying-item-bot']} red`}>
              <div className={`${styles['section-saying-item-title']} red`}>
                michelle.obama
              </div>
              <div className={`${styles['section-saying-item-sub']} red`}>
                Just got my @fauna package!! I am so happy with my package!!
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}

function Quotes() {
  return (
    <div className={`${styles['section-quotes']} red`}>
      <div className={`${styles['section-quotes-container']} red`}>
        <div className={`${styles['section-quotes-quote']} red`}>
          <Fade>
            “Fauna makes it possible for women to get birth control without ever
            having to go to the doctor&apos;s office, or the pharmacy or leaving
            home.”
          </Fade>
        </div>
        <div className={`${styles['section-quotes-image-container']} red`}>
          <Fade>
            <div className={`${styles['section-quotes-image']} red`}>
              <img
                className={`${styles['quotes-image']} red`}
                src="/landing/darwin.svg"
              />
            </div>
          </Fade>
          <Fade>
            <div className={`${styles['section-quotes-image']} red`}>
              <img
                className={`${styles['quotes-image']}  ${styles['image-medium']} red`}
                src="/landing/medium.svg"
              />
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}

function Why() {
  return (
    <div className={`${styles['section-why']} red`}>
      <div className={`${styles['section-why-title']} red`}>
        <Fade>Why Fauna?</Fade>
      </div>
      <div className={`${styles['section-why-container']} red`}>
        {/* ITEM */}
        <Fade>
          <div className={`${styles['section-why-item']} red`}>
            <div className={`${styles['section-why-logo']} red`}>
              <img src="/landing/why1.svg" />
            </div>
            <div className={`${styles['section-why-text-title']} red`}>
              $0 with insurance. Lowest prices without.
            </div>
            <div className={`${styles['section-why-text-sub']} red`}>
              The most affordable prices. And if you’re covered, we deal with
              your insurance, so you don&apos;t have to pay anything!
            </div>
          </div>
        </Fade>
        {/* END */}

        {/* ITEM */}
        <Fade>
          <div className={`${styles['section-why-item']} red`}>
            <div className={`${styles['section-why-logo']} red`}>
              <img src="/landing/why2.svg" />
            </div>
            <div className={`${styles['section-why-text-title']} red`}>
              Never worry about running out
            </div>
            <div className={`${styles['section-why-text-sub']} red`}>
              Count on us to deliver your birth control on time.
            </div>
          </div>
        </Fade>
        {/* END */}

        {/* ITEM */}
        <Fade>
          <div className={`${styles['section-why-item']} red`}>
            <div className={`${styles['section-why-logo']} red`}>
              <img src="/landing/why3.svg" />
            </div>
            <div className={`${styles['section-why-text-title']} red`}>
              Free, discreet shipping
            </div>
            <div className={`${styles['section-why-text-sub']} red`}>
              Free shipping so you can save yourself the trip to the pharmacy -
              without any extra costs.
            </div>
          </div>
        </Fade>
        {/* END */}

        {/* ITEM */}
        <Fade>
          <div className={`${styles['section-why-item']} red`}>
            <div className={`${styles['section-why-logo']} red`}>
              <img src="/landing/why4.svg" />
            </div>
            <div className={`${styles['section-why-text-title']} red`}>
              100% online
            </div>
            <div className={`${styles['section-why-text-sub']} red`}>
              You&apos;re always in control: Consultation, prescriptions,
              personalized care from the comfort of your home and on your own
              time.
            </div>
          </div>
        </Fade>
        {/* END */}
      </div>
    </div>
  );
}

function Started() {
  return (
    <div className={`${styles['section-started']} red`}>
      <div className={`${styles['section-started-container']} red`}>
        <div className={`${styles['section-started-cta']} red`}>
          <Fade>
            Ready to take control of your birth control? <br /> Take a few
            minutes to complete your online health profile.
          </Fade>
        </div>
        <Fade>
          <Link href="/birth-control">
            <button className={`${styles['section-started-btn']} red`}>
              Get Started
            </button>
          </Link>
        </Fade>
      </div>
    </div>
  );
}

function Privacy() {
  return (
    <div className={`${styles['section-privacy']} red`}>
      <div className={`${styles['section-privacy-title']} red`}>
        <Fade>We take your privacy very seriously</Fade>
      </div>
      <Fade>
        <div className={`${styles['section-privacy-logo1']} red`}>
          <img src="/landing/privacy1.svg" />
        </div>
      </Fade>
      <div className={`${styles['section-privacy-sub']} red`}>
        <Fade>
          We comply with PIPEDA and PHIPA regulations and take all steps to
          ensure that your information stays safe and private.
        </Fade>
      </div>
      <div className={`${styles['section-privacy-title2']} red`}>
        <Fade>Accredited by</Fade>
      </div>
      <Fade>
        <div className={`${styles['section-privacy-logo2']} red`}>
          <img src="/landing/privacy4.png" />
        </div>
      </Fade>
      <Fade>
        <div className={`${styles['section-privacy-logo3']} red`}>
          <img src="/landing/privacy3.png" />
        </div>
      </Fade>
    </div>
  );
}
